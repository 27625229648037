import React, { useState, useEffect } from "react";
import ProductLines from "../modules/ProductLines";
import { DealBuilderApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent, DocCreatorApi } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin, CanDelete } from "../services/Permissions";
import ModuleFunctions from "../modules/ModuleFunctions";
import FullScreenDialogBox from "../modules/FullScreenDialogBox";
import DatePicker from "../common/DatePicker";
import ProductOverview from "../modules/ProductOverview";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveWarningDialogBox from "../common/SaveWarningDialog";
import OptionalDocsDialogBox from "../modules/OptionalDocsDialogBox";
import SupportingProductDoc from "../modules/SupportingProductDoc";

export default function ProductPage(props) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);
  const [dataLog, setDataLog] = useState(false);
  const [open, setOpen] = useState(false);

  const [bus, setBus] = useState(false);
  const [salesOpp, setSalesOpp] = useState(false);

  const [openFullBox, setOpenFullBox] = useState(false)
  const [selectedRow, setSelectedRow] = useState(false)
  const [productLineIndex, setProductLineIndex] = useState(false)
  const [optionLineIndex, setOptionLineIndex] = useState(false)
  const [saveWarningDialogBox, setSaveWarningDialogBox] = useState(false)

  const [optionalDocumentDialogBox, setOptionalDocumentDialogBox] = useState(false)
  const [optionalDocData, setOptionalDocData] = useState(false);

  const [liveProductDocument, setLiveProductDocument] = useState(false)
  const [liveProductSchedules, setLiveProductSchedules] = useState(false)

  const [structure, setStructure] = useState(false)

  const currentDate = new Date();
  const edit = CanUpdate("deal-builder");
  const uneditable = !data.approved || data.approved && (data.responsible_id !== props.context.auth.contact) || data.approved && new Date(data.valid_to) < currentDate || data.deal_approval || data.selected


  const handleCheckBox = (data) => {
    
  }

  const calculateProductLinePrice = (index , event ) => {

    const GBP = value => Currency(value, { symbol: '£', decimal: '.', separator: ',' });
    const newData = { ...data };

    const value = (event.target.value.indexOf(".") >= 0)
        ? (event.target.value.substr(0, event.target.value.indexOf(".")) + event.target.value.substr(event.target.value.indexOf("."), 3))
        : event.target.value;

    newData.lines[index][event.target.name] = value

    const cost = GBP(newData.lines[index]['unit_price']);
    const total = GBP(newData.lines[index]['price']);

    const markup = GBP(cost).multiply((parseFloat(newData.lines[index]['quantity']) || 0));
    const work = GBP(cost).add(markup);

    newData.lines[index]['price'] = markup.value;
    
    dealChange({ ...newData , changed: true });    
  }


  const handleDynamicLine = (cost, index) => {
    const dataCopy = {...data}
    dataCopy.lines[productLineIndex].options[index][cost.target.name] = cost.target.valueAsNumber
    dealChange({ ...dataCopy , changed: true });
  } 

  const handleOpenSaveWarningDialogBox = () => {
    setSaveWarningDialogBox(true)
  }

  const handleCloseSaveWarningDialogBox = () => {
    setSaveWarningDialogBox(false)
  }

  const deleteOptionalDocument = (index) => {
    const dataCopy = {...data}
    dataCopy.lines[productLineIndex].options[optionLineIndex].option_docs.splice( index , 1 )

    dealChange({ ...dataCopy , changed: true });

  }

  const handleOpenOptionalDocumentDialogBox = (index) => {
    setOptionalDocData(data.lines[productLineIndex].options[index]["option_docs"])
    setOptionLineIndex(index)
    setOpenFullBox(false)
    setOptionalDocumentDialogBox(true)
  }

  const handleCloseOptionalDocumentDialogBox = () => {
    setOptionalDocumentDialogBox(false)
    setOptionLineIndex(false)
    setOpenFullBox(true)
    setOptionalDocData(false)
  }

  const closeFullBox = () => {
    setProductLineIndex(false)
    setOpenFullBox(false)
  }

  const handleFullBox = (index) => {
    setProductLineIndex(index)
    setSelectedRow(data.lines[index]);
    setOpenFullBox(true)
  }

  const chargesChange = (data) => {
    setCharges(data);
  };

  const periodsChange = (data) => {
    setPeriods(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const salesOppChange = (data) => {
    setSalesOpp(data)
  }

  const structureChange = (data) => {
    setStructure(data)
  }

  const dealChange = (data) => {
    setData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const getPeriodicData = (pCode) => {
    const result = periods.find(x => x.id == pCode)
    if (result) {
      return result.name
    }
    return "N/A"
  }

  const getChargeData = (cCode) => {
    const cResult = charges.find(x => x.id == cCode)
    if (cResult) {
      return cResult.name
    }
    return "N/A"
  }

  const getProduct = async () => {
    const res = await DealBuilderApi.getLiveProduct(props.route.match.params.id);

    if (res.success) {
      setData( {...res.data ,  dealChange: dealChange })
      getProductDocument(res.data.id , res.data)
      getProductSchedules(res.data.id , res.data)
    }
  };

  const getProductDocument = async (id , obj) => {
    const res = await DealBuilderApi.liveProductGetDocuments(id , obj);
    if (res.success) {
      setLiveProductDocument( res.data)
    }
  };

  const getProductSchedules = async (id , obj) => {
    const res = await DealBuilderApi.liveProductGetSchedules(id , obj);
    if (res.success) {
      setLiveProductSchedules( res.data )
    }
  };

  const getProductLog = async () => {
    const res = await DealBuilderApi.getLiveProductApprovedLog(props.route.match.params.id);
    if (res.success) {
      setDataLog(res.data)
    }
  };

  const updateLine = (index, obj) => {
    let tempProduct = { ...data };
    tempProduct.lines[index][obj.target.name] = parseFloat(obj.target.value);
    dealChange({ ...tempProduct , changed: true });
  }

  const updateValidToDealDate = (obj) => {
    let selectedDate = { ...data };
    selectedDate["valid_to"] = obj.$d;
    dealChange({ ...selectedDate , changed: true });
  }

  const updateValidFromDealDate = (obj) => {
    let selectedDate = { ...data };
    selectedDate["valid_from"] = obj.$d;
    dealChange({ ...selectedDate , changed: true });
  }


  const updateValidToDynamicDealDate = (obj, index) => {
    const dataCopy = { ...data }
    let lowest = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options[index]["valid_to"] = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options.forEach( (e) => {
      let dateObj = new Date(e.valid_to);
      if(dateObj < lowest){
        lowest = dateObj;
      }
    })

    dataCopy['valid_to'] = lowest;

    dealChange({ ...dataCopy , changed: true });
  }


  const updateValidFromDynamicDealDate = (obj, index) => {

    const dataCopy = { ...data }
    let highest = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options[index]["valid_from"] = new Date(obj.$d);

    dataCopy.lines[productLineIndex].options.forEach( (e) => {
      let dateObj = new Date(e.valid_from);
      if(dateObj > highest){
        highest = dateObj;
      }
    })

    dataCopy['valid_from'] = highest;

    dealChange({ ...dataCopy , changed: true });
  }
  

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving Product, Please Wait...." });
    const res = await DealBuilderApi.updateLiveProduct(props.route.match.params.id, data, "builder");
    if (res.success) {
      setLoading({ status: true, data: "Successfully updated product" });
      setTimeout(() => {
        getProduct();
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          //history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  // This handles the snackbar --------------------------------
  const openSnackbarMessege = () => {
    //setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const displaySnackBarMessage = async (info) => {

    if(uneditable && !info.approved){
      return (<Alert severity="error">Product has been approved. Unable to make changes</Alert>)
    } else if( uneditable && info.approved && (info.responsible_id !== props.context.auth.contact) ){
      return (<Alert severity="error">Product has been approved. Unable to make changes</Alert>)
    } else if( uneditable && info.approved && new Date(info.valid_to) < currentDate ){
      return (<Alert severity="error">Product has been retired. Unable to make changes</Alert>)
    }  else if( uneditable && info.deal_approval ){
      return (<Alert severity="error">Deal has been approved. Unable to make changes</Alert>)
    }  else if( uneditable && info.selected ){
      return (<Alert severity="error">Unselect product to make changes</Alert>)
    }
  }
  //---------------------------------------------------------------

  useEffect(
    () => {
      ModuleFunctions.getStructure( {structureChange: structureChange} );
      getProduct();
      getProductLog();
      ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
      ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
      ModuleFunctions.getSalesOpps({ salesOppChange: salesOppChange });
      ModuleFunctions.getBusinesses({ change: busChange });

    }, []
  )


  if(data && structure){
  return (
    <>
      <AppHeaderComponent
        saveBtn={ data.changed ? handleSave : null }
        context={props.context}
        theme={props.context.theme}
        name="deal-builder"
        subpage={"product"}
        spacer={true}
        unsavedItems={ data.changed }
        showUnsavedWarningPromt={handleOpenSaveWarningDialogBox}
      />

      <ProductOverview 
        data={data}
        structure={structure}
        type="view" 
        bus={bus}
      />

      <div className="block">
        <div className="container-fluid">
          <div style={{ width: '100%' }}>
            <div style={{ display: "flex", width: "50%", justifyContent: "space-between", marginTop: "15px", marginBottom: "15px" }} >
              <DatePicker date={data.valid_from} label="Valid from" name={"valid_from"} updateDealDate={updateValidFromDealDate} uneditable={uneditable}/>
              <DatePicker date={data.valid_to} label="Expiry date" name={"valid_to"} updateDealDate={updateValidToDealDate} uneditable={uneditable}/>
            </div>
          </div>
        </div>
      </div>

      <ProductLines
        productLines={data.lines}
        handleFullBox={handleFullBox}
        updateLine={updateLine}
        handleCheckBox={handleCheckBox}
        isDealApproved={data.deal_approval}
        errors={errors}
        periods={periods}
        uneditable={uneditable}
        openSnackbarMessege={openSnackbarMessege}
        getPeriodicData={getPeriodicData}
        calculateProductLinePrice={calculateProductLinePrice}
      />
      
      { liveProductDocument && liveProductSchedules ?
      <SupportingProductDoc
        productDocuments={liveProductDocument}
        productSchedules={liveProductSchedules}
      />
      : null}

      <FullScreenDialogBox
        data={selectedRow}
        dealChange={dealChange}
        getChargeData={getChargeData}
        getPeriodicData={getPeriodicData}
        closeFullBox={closeFullBox}
        openFullBox={openFullBox}
        handleDynamicLine={handleDynamicLine}
        updateValidFromDynamicDealDate={updateValidFromDynamicDealDate}
        updateValidToDynamicDealDate={updateValidToDynamicDealDate}
        productLineIndex={productLineIndex}
        type={"builder"}
        handleOpenOptionalDocumentDialogBox={handleOpenOptionalDocumentDialogBox}
        uneditable={uneditable}
      />

      {data ? 
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        displaySnackBarMessage(data) 
      </Snackbar>
      : null}

      <SaveWarningDialogBox
        open={saveWarningDialogBox}
        close={handleCloseSaveWarningDialogBox}
      />

      <OptionalDocsDialogBox
        open={optionalDocumentDialogBox}
        close={handleCloseOptionalDocumentDialogBox}
        data={optionalDocData}
        deleteOptionalDocument={deleteOptionalDocument}
        optionLineIndex={optionLineIndex}
        type="builder"
      />
        
      <ApiLoaderComponent
        status={loading.status}
        data={loading.data}
      />
      
    </>
    
  )} else {
    return <LoadingComponent color={props.context.theme.sidebar.background}/>;
  }
}
