import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../common/DataTable";
import { DealBuilderApi , ApiLoaderComponent, LoadingComponent } from "@unity/components";
import ModuleButton from "../../common/ModuleButton";
import WarningCheck from "../../common/WarningCheck";
import { CanReadAll } from "../../services/Permissions";
import ModuleFunctions from "../ModuleFunctions";
import dayjs from "dayjs";

export default function ActiveConIndex({ context, bus}) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningData, setWarningData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [personnel, setPersonnel] = useState(false)

  const [salesOpp, setSalesOpp] = useState(false);

  let dealBuildPermission = context.auth.access["deal-config"]

  const readAll = CanReadAll("deal-config");

  const history = useHistory();

  const checkWarning = (data) => {
    let text = `You are about to archive the deal ${data.ref}: ${data.name}. Are you sure you want to continue?`;

    setWarning(true);
    setWarningData({
      id: data.id,
      info: text
    });
  };

  const getDeals = async () => {

    const res = await DealBuilderApi.getResponsibleActiveDeals({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if(res.success) {
      setData(res.data);
      if(pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if ( pagination ) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if(pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleLinkClick = (event, params, path) => {
      event.preventDefault();
      history.push({
        pathname: `/deal-config/${path}/${params.id}`,
        state: { ...params }
      });
  };

  const salesOppChange = (data) => {
    setSalesOpp(data)
  }

  const handleArchive = async (id) => {
    setLoading({ status: true, data: 'Archiving deal, please wait...' });
    setWarning(false);
    setWarningData(false);

    const res = await DealsApi.dealArchive(id, {});

    if (res.success) {
      setLoading({ status: false });
      setReplaceLog(false);
    } else {
      setLoading({ status: true, data: res.message });
      setTimeout(() => {
        setLoading({ status: false });
      }, 3000);
    }
    setFetchTrigger(fetchTrigger + 1);
  };

  const columns = [
    { field: "ref", headerName: "Ref#", flex: 0.5 },
    { field: "name", headerName: "Product Name", flex: 1 },
    {
      field: "sbu_id",
      headerName: "Business Unit",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.sbu_id) {
          const pc = bus.find(e => cellValues.row.sbu_id === e.id)
          return pc.name;
        } else {
          return null;
        }
      }
    },
    {field: "sales_opp_id", headerName: "Sales Opp Name", minWidth: 200,
    renderCell: (cellValues) => {
      if (cellValues.row.sbu_id) {
        const pc = salesOpp.find(e => cellValues.row.sales_opp_id === e.id)
        return pc.sales_order_ref;
      } else {
        return null;
      }
    }
  
    },
    {
      field: "owner_name", headerName: "Product Owner", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.owner_id && personnel) {
          const un = personnel.find(x => x.contact_id == cellValues.row.owner_id)
          if(un === undefined){
            return "User not found"
          }
          return un.name;
        } else {
          return null
        }
      }
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_from) {
          return dayjs(cellValues.row.valid_from).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>
        }
      }
    },
    {
      field: "valid_to", headerName: "Valid To", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_to) {
          return dayjs(cellValues.row.valid_to).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>
        }
      }
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (
          <div>
            <ModuleButton
              btnIcon="visibility"
              style={{
                background: context.theme.sidebar.background,
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, cellValues.row, "view")}
              tip="View"
            />
          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };
  
  useEffect(() => {
    getDeals();
    ModuleFunctions.getPersonnel({ personnelChange: setPersonnel });
    ModuleFunctions.getSalesOpps({ salesOppChange: salesOppChange });
  }, []);


  if (dealBuildPermission.R && data && bus && personnel) {
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={false}
          handleDeleteArray={false}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />

        <WarningCheck
          open={warning}
          setOpen={setWarning}
          dialogData={warningData}
          setDialogData={setWarningData}
          handleSubmit={handleArchive}
        />

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
