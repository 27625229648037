import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppHeaderComponent } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../services/Permissions";
import ModuleFunctions from "../modules/ModuleFunctions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleButton from "../common/ModuleButton";
import ActiveIndex from "../modules/ActiveIndex";
import ArchivedIndex from "../modules/ArchivedIndex";
import Settings from "../modules/Settings";
import DraftIndex from "../modules/DraftIndex";
import DeletedIndex from "../modules/DeletedIndex";
import AcceptedIndex from "../modules/AcceptedIndex";

export default function Deals(props) {
  const [value, setValue] = useState(0);
  const [bus, setBus] = useState(false);

  const create = CanCreate("deal-builder");
  const admin = CanModuleAdmin("deal-builder");

  const busChange = (data) => {
    setBus(data);
  };
  
  const handleChange = async (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    { icon: "build", label: "active" },
    { icon: "update", label: "draft" },
    { icon: "archive", label: "archived" },
  ];

  if (admin) {
    tabs.push(
      { icon: "settings", label: "module settings" }
    )
  };

  useEffect(() => {
    ModuleFunctions.getBusinesses({ change: busChange });
  }, []);

  return (
    <>
      {create && value !== 3 ? (
        <AppHeaderComponent
          addNew={() => <Link to={"/deal-builder/create"}>
            <ModuleButton
              text="Add Deal"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: props.context.theme.top_menu.button_background,
                  color: props.context.theme.top_menu.button_font,
                }
              }
            />
          </Link>}
          context={props.context}
          theme={props.context.theme}
          name={"deal-builder"}
          spacer={true}
        />
      ) : (
        <AppHeaderComponent
          context={props.context}
          theme={props.context.theme}
          name={"deal-builder"}
          spacer={true}
        />
      )}
      
      <ModuleTabs tabs={tabs} value={value} setValue={setValue} handleChange={handleChange} />
      <div style={{ paddingTop: 86 }}>
      {value === 0 && 
          <ActiveIndex
            context={props.context}
            bus={bus}
          />}
        {value === 1 && 
          <DraftIndex
            context={props.context}
            bus={bus}
          />}
        {value === 2 && 
          <ArchivedIndex
            context={props.context}
            bus={bus}
          />}


        {value === 3 &&
          <Settings context={props.context} />}
      </div>
    </>
  );
}
