import React, { useState, useEffect } from "react";
import { AppHeaderComponent } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../../services/Permissions";
import ModuleFunctions from "../../modules/ModuleFunctions";
import ModuleTabs from "../../common/ModuleTabs";
import ActiveConIndex from "../../modules/deal-config/ActiveConIndex";
import WorkingIndex from "../../modules/deal-config/WorkingIndex";
import ArchiveConIndex from "../../modules/deal-config/ArchiveConIndex";


export default function Deals(props) {
    const [value, setValue] = useState(0);
    const [bus, setBus] = useState(false);

    const create = CanCreate("deal-config");
    const admin = CanModuleAdmin("deal-config");

    const busChange = (data) => {
        setBus(data);
    };

    const handleChange = async (event, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        { icon: "build", label: "active" },
        { icon: "update", label: "draft" },
        { icon: "archive", label: "archived" },
    ];


    useEffect(() => {
        ModuleFunctions.getBusinesses({ change: busChange });
    }, []);

    return (
        <>
            <AppHeaderComponent
                context={props.context}
                theme={props.context.theme}
                name={"deal-config"}
                spacer={true}
            />

            <ModuleTabs tabs={tabs} value={value} setValue={setValue} handleChange={handleChange} />
            <div style={{ paddingTop: 86 }}>
                {value === 0 &&
                    <ActiveConIndex
                        context={props.context}
                        bus={bus}
                    />}
                {value === 1 &&
                    <WorkingIndex
                        context={props.context}
                        bus={bus}
                    />}
                {value === 2 &&
                    <ArchiveConIndex
                        context={props.context}
                        bus={bus}
                    />}


            </div>
        </>
    );
}
