import React from "react";
import { TextField } from "@mui/material";
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


const DatePicker = (props) => {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
                <DesktopDatePicker
                    name={props.name} 
                    label={props.label}
                    inputFormat="DD/MM/YYYY"
                    value={props.date}
                    onChange={(newDate) => props.uneditable ? null : props.updateDealDate(newDate, props.index)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
    )
}

export default DatePicker;
