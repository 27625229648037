import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "../common/DataTable";
import { DealBuilderApi, ApiLoaderComponent, LoadingComponent, AppHeaderComponent } from "@unity/components";
import { CanDelete, CanReadAll, CanUpdate } from "../services/Permissions";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Object } from "core-js";
import dayjs from "dayjs";

export default function DraftIndex({ context, bus }) {
  const [loading, setLoading] = useState({ status: false });
  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState(true);// Flag to switch between unpaginated and paginated data!
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [searchText, setSearchText] = useState('');
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if getDocs() needs to be called. The value doesn't matter, just the change!
  const [personnel, setPersonnel] = useState(false)


  let dealBuildPermission = context.auth.access["deal-builder"]

  const edit = CanUpdate("deal-builder");
  const readAll = CanReadAll("deal-builder");

  const history = useHistory();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/deal-builder/${path}/${params.id}`,
      state: { ...params }
    });
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };


  const getDeals = async () => {

    const res = await DealBuilderApi.getWorkingDeals({ read_all: readAll, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if (res.success) {
      setData(res.data);
      if (pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setData([]);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const deselectProductToggle = (data) => {
    handleChangeRequestBox()
  }


  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await DealsApi.deleteDeal(id);
  };

  const handleDeleteArray = async (array) => {
    setLoading({ status: true, data: "Deleting deals, please wait..." });
    const unresolvedPromises = array.map(id => handleDelete(id));

    await Promise.all(unresolvedPromises).then(success => {
      setLoading({ status: true, data: "Succssfully deleted deals!" });
    }).catch(error => {
      setLoading({ status: true, data: `Unable to delete deal with id: ${error}` });
    });

    triggerChange();

    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  const handleDealApproveRequest = async (obj) => {
    setLoading({ status: true, data: 'Checking deal for approval request, please wait...' });

    const res = await DealBuilderApi.dealApprove(obj.id, obj);

    if (res.success) {
      setLoading({ status: true, data: "Successfully requested deal approval" });
      setTimeout(() => {
        triggerChange();
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setLoading({ status: true, data: 'Validation Errors!, Please Wait....' });
        setTimeout(() => {
          history.push({
            pathname: `/deal-builder/edit/${id}`,
            state: { hasErrors: res.errors }
          });
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      }
    }
  }

  const handleCancelRequest = async (id) => {
    let message_a = 'Cancelling approval request for deal, please wait...';
    let message_b = 'Unable to cancel approval request for deal!';

    setLoading({ status: true, data: message_a });
    const res = await DealsApi.dealCancellation(id, {});

    if (res.errors) {
      setLoading({ status: true, data: message_b });
      setTimeout(setLoading({ status: false }), 3000);
    } else {
      setLoading({ status: false });
    }

    setFetchTrigger(fetchTrigger + 1);
  }

  const columns = [
    { field: "ref", headerName: "Ref#", flex: 0.5 },
    { field: "name", headerName: "Product Name", flex: 1 },
    {
      field: "sbu_id",
      headerName: "Business Unit",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.sbu_id) {
          const pc = bus.find(e => cellValues.row.sbu_id === e.id)
          return pc.name;
        } else {
          return null;
        }
      }
    },
    {
      field: "owner_name", headerName: "Product Owner", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.owner_id && personnel) {
          const un = personnel.find(x => x.contact_id == cellValues.row.owner_id)
          if(un === undefined){
            return "User not found"
          }
          return un.name;
        } else {
          return null
        }
      }
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_from) {
          return dayjs(cellValues.row.valid_from).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>
        }
      }
    },
    {
      field: "valid_to", headerName: "Valid To", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_to) {
          return dayjs(cellValues.row.valid_to).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>
        }
      }
    },
    {
      field: "status", headerName: "Status", flex: 0.3,
      renderCell: (cellValues) => {
        if (edit && !cellValues.row.requested && (cellValues.row.owner_id == context.auth.contact) && (cellValues.row.valid_to !== null) && (cellValues.row.sales_opp_id !== null)) {
          return <IconButton><CheckCircleIcon style={{ color: "green" }} /></IconButton>
        }

        else {
          return <Tooltip title={
            <>
              <span>Unable to submit.</span>
              <br></br>
              {(cellValues.row.owner_id == context.auth.contact) ? null : <span>You are not the deal owner</span>}
              <br></br>
              {(cellValues.row.valid_to !== null) ? null : <span>No valid expiry date</span>}
              <br></br>
              {(cellValues.row.sales_opp_id !== null) ? null : <span>No Sales Op id selected</span>}
            </>
          }
            style={{ color: "blue" }}
          ><IconButton ><WarningIcon style={{ color: "red" }} /></IconButton></Tooltip>
        }
      }
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {

        return (

          <div>
            { (cellValues.row.owner_id == context.auth.contact) ?
            <ModuleButton
              btnIcon="edit"
              style={{
                background: "orange",
                color: "white",
              }}
              onClick={(event) => handleLinkClick(event, { ...cellValues.row, hasErrors: false }, "edit")}
              tip="Update"
            /> :

            <ModuleButton
              btnIcon="visibility"
              style={{
                //background: context.theme.sidebar.background,
                background: "#1976d2",
                color: "white"
              }}
              onClick={(event) => handleLinkClick(event, { ...cellValues.row, hasErrors: false }, "edit")}
              tip="View"
            />
            }

            {edit && (cellValues.row.owner_id == context.auth.contact) && (cellValues.row.valid_to !== null) && (cellValues.row.valid_from !== null)
             && cellValues.row.sales_opp_id && 
              <ModuleButton
                btnIcon="sendOutlinedIcon"
                style={{
                  background: "#18b480",
                  color: "white",
                  marginLeft: 10
                }}
                //onClick={(event) => handleLinkClick(event, { ...cellValues.row, hasErrors: false }, "edit")}
                onClick={() => handleDealApproveRequest(cellValues.row)}
                tip="Approve Deal"
              />
            }

            {edit && cellValues.row.approved && (cellValues.row.owner_id == context.auth.contact) && (cellValues.row.valid_to !== null) && (cellValues.row.valid_from !== null) ?
              <ModuleButton
                btnIcon="deselect"
                style={{
                  background: "red",
                  color: "white",
                  marginLeft: 10
                }}
                onClick={() => deselectProductToggle(cellValues.row)}
                tip="request change"
              /> : null
            }

          </div>
        );
      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 680
  };


  useEffect(() => {
    getDeals();
    ModuleFunctions.getPersonnel({ personnelChange: setPersonnel });
  }, [ fetchTrigger ]);


  if (dealBuildPermission.R && data) {
    return (
      <>

        <DataTable
          columns={columns}
          data={data}
          pagination={pagination}
          options={options}
          allowDelete={CanDelete("deal-builder")}
          handleDeleteArray={handleDeleteArray}
          searchText={searchText}
          setSearchText={setSearchText}
          fetchTrigger={fetchTrigger}
          setFetchTrigger={setFetchTrigger}
        />
        <ApiLoaderComponent status={loading.status} data={loading.data} />


      </>
    );
  } else {
    return <LoadingComponent />;
  }
}
